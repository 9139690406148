<template>
  <BOverlay
    :show="spinner || cargandoInforme"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <!-- Aquí puedes poner el mensaje que desees mostrar -->
    <template #overlay>
      <div class="text-center d-flex align-items-start justify-content-center mb-5 pb-5" style="height: 100%;">
        <h3>Cargando PDF...</h3> <!-- Mensaje personalizado -->
      </div>
    </template>

    <BCard>
      <!-- * FILTROS -->
      <BRow>

        <!-- CURSOS -->
        <BCol
          cols="12"
          md="4"
          class="mb-50 mb-md-0"
        >
          <BOverlay
            :show="cargandoCursos"
            spinner-variant="primary"
          >
            <v-select
              v-model="selectedCurso"
              placeholder="Seleccione un curso..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :options="optionsCursos"
              :reduce="option => option.value"
              input-id="id_curso"
              style="border-radius: 6px;"
              :disabled="disabledCursos || cargandoInforme"
              @input="changeCurso()"
            />
          </BOverlay>
        </BCol>

        <!-- PERIODOS -->
        <BCol
          cols="12"
          md="4"
          class="mb-50 mb-md-0"
        >
          <BOverlay
            :show="cargandoPeriodos"
            spinner-variant="primary"
          >
            <v-select
              v-model="selectedPeriodo"
              placeholder="Seleccione un periodo..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :options="optionsPeriodos"
              :reduce="option => option.value"
              input-id="id_curso"
              style="border-radius: 6px;"
              :disabled="disabledPeriodos || cargandoInforme"
              @input="changePeriodo()"
            />
          </BOverlay>
        </BCol>

        <!-- TIPO INFORME -->
        <BCol
          cols="12"
          md="4"
          class="mb-50 mb-md-0"
        >
          <BOverlay
            :show="cargandoTipoInformes"
            spinner-variant="primary"
          >

          <v-select
              v-model="selectedTipoInforme"
              placeholder="Seleccione un informe..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :options="optionsTipoInformes"
              :reduce="option => option.value"
              input-id="id_curso"
              style="border-radius: 6px;"
              :disabled="disabledTipoInforme || cargandoInforme"
              @input="changeTipoInforme()"
            />
          </BOverlay>
        </BCol>
      </BRow>

      <!-- TABLA -->
      <BRow v-if="selectedCurso !== null">

        <!-- * ESTADÍSTICAS -->
        <BCol
          cols="12"
          md="8"
          sm="8"
          class="d-flex justify-content-start my-2"
        >
          <BMedia 
            v-for="item in cuentaAlumnos"
            :key="item.icon"
            :class="item.customClass" 
            no-body
            class="mr-5"
          >
            <BMediaAside class="mr-2">
              <BAvatar
                size="48"
                :variant="item.color"
              >
                <FeatherIcon
                  size="24"
                  :icon="item.icon"
                />
              </BAvatar>
            </BMediaAside>
            <BMediaBody class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <BCardText class="font-small-3 mb-0">
                {{ item.subtitle }}
              </BCardText>
            </BMediaBody>
          </BMedia>
        </BCol>
        
        <!-- FILTRO & VER INFORMES -->
        <BCol
          cols="12"
          md="4"
          class="d-flex justify-content-end mt-2"
        >
          <inputFiltro
            :filter.sync="filter"
            :cols="false"
            size="md"
            class="mr-1 w-100"
          />
          <div>
          <BButton
            v-if="!bloquesAlert"
            variant="primary"
            class="btn"
            
            @click="verInforme()"
          >
            <span class="text-nowrap">Ver Informe</span>
          </BButton>
        </div>
        </BCol>

        <!-- TABLA -->
        <BCol cols="12">
            <!-- striped -->
          <b-alert
            v-if="bloquesAlert"
            variant="info"
            show
            class="text-center mt-25 mr-25 ml-25 pb-2 pt-2"
          >
            {{alertMsg}}
          </b-alert>
          <BTable
            v-else
            small
            hover
            noCollapse
            responsive
            :items.sync="items"
            :fields="visibleFields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="cargando"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <!-- CARGANDO -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- header: CHECK -->
            <template #head(colCheck)="data">
              <BFormCheckbox
                :id="data.label"
                v-model="chkTodo"
                :disabled="
                  selectedTipoInforme !== 1 &&
                  selectedTipoInforme !== 2 &&
                  selectedTipoInforme !== 6
                "
              />
            </template>

            <!-- col: CHECK -->
            <template #cell(colCheck)="data">
              <BFormCheckbox
                :id="`chk-${data.item.id_persona_rol_alumno}`"
                v-model="data.item.chkSelected"
              />
            </template>

            <!-- col: ORDEN -->
            <template #cell(orden)="data">
              <estudiante-retirado
                :data.sync="data.item.orden.toString()"
                :fecha_retiro.sync="data.item.fecha_retiro"
                :mayus="false"
              />
            </template>

            <!-- col: NOMBRE COMPLETO -->
            <template #cell(nombre_completo)="data">
              <estudiante-retirado
                :data.sync="data.item.nombre_completo"
                :fecha_retiro.sync="data.item.fecha_retiro"
                :mayus="true"
              />
            </template>

            <!-- INFORME PARCIAL -->
            <!-- col: Observaciones PARCIAL -->
            <template #cell(observacion_parcial)="data">
              <BCardText
                :title="typeof data.item.observacionParcial !== 'undefined'
                  ? data.item.observacionParcial.observacion
                  : ''"
              >
                {{ typeof data.item.observacionParcial !== 'undefined' ? cortaObservacion(data.item.observacionParcial.observacion) : '' }}
              </BCardText>
            </template>

            <!-- col: Acciones PARCIAL -->
            <template #cell(acciones_parcial)="data">
              <acciones-parcial
                modulo="informeParcial"
                :observacion_parcial.sync="data.item.observacionParcial"
                :id_persona_rol_alumno.sync="data.item.id_persona_rol_alumno"
                :id_cursos.sync="data.item.id_cursos"
                :id_periodo.sync="selectedPeriodo"
                :nombre_estudiante="data.item.nombre_completo"
                :curso.sync="data.item.curso"
              />
            </template>

            <!-- INFORME PERIODO -->
            <!-- col: Observaciones PERIODO -->
            <template #cell(observacion_periodo)="data">
              <BCardText
                :title="typeof data.item.observacionPeriodo !== 'undefined'
                  ? data.item.observacionPeriodo.observacion
                  : ''"
              >
                {{ typeof data.item.observacionPeriodo !== 'undefined' ? cortaObservacion(data.item.observacionPeriodo.observacion) : '' }}
              </BCardText>
            </template>

            <!-- col: acciones PERIODO -->
            <template #cell(acciones_periodo)="data">
              <acciones-periodo
                modulo="informeParcial"
                :observacion_periodo.sync="data.item.observacionPeriodo"
                :id_persona_rol_alumno.sync="data.item.id_persona_rol_alumno"
                :id_cursos.sync="data.item.id_cursos"
                :id_periodo.sync="selectedPeriodo"
                :nombre_estudiante="data.item.nombre_completo"
                :curso.sync="data.item.curso"
              />
            </template>

            <!-- INFORME ANUAL -->
            <!-- col: Observaciones ANUAL -->
            <template #cell(observacion_anual)="data">
              <BCardText
                :title="typeof data.item.observacionAnual !== 'undefined'
                  ? data.item.observacionAnual.observacion
                  : ''"
              >
                {{ typeof data.item.observacionAnual !== 'undefined' ? cortaObservacion(data.item.observacionAnual.observacion) : '' }}
              </BCardText>
            </template>

            <!-- col: acciones ANUAL -->
            <template #cell(acciones_anual)="data">
              <acciones-anual
                modulo="informeAnual"
                :observacion_anual.sync="data.item.observacionAnual"
                :id_persona_rol_alumno.sync="data.item.id_persona_rol_alumno"
                :id_cursos.sync="data.item.id_cursos"
                :nombre_estudiante="data.item.nombre_completo"
                :curso.sync="data.item.curso"
              />
            </template>

            <!-- INFORME PERSONALIDAD -->
            <!-- col: acciones PERSONALIDAD -->
            <template #cell(acciones_personalidad)="data">

              <acciones-personalidad
                modulo="informePerso"
                texto="informe"
                :personalidad="data.item.personalidad"
                :id_periodo.sync="selectedPeriodo"
                :id_persona_rol_alumno.sync="data.item.id_persona_rol_alumno"
                :id_cursos.sync="data.item.id_cursos"
                :nombre_estudiante="data.item.nombre_completo"
                :curso.sync="data.item.curso"
              />
                <!-- :indicadoresInformePersonalidad.sync="informePersonalidadCurso" -->

            </template>

          </BTable>
        </BCol>
      </BRow>

      <!-- Alertas -->
      <div
        v-else
        class="mt-3"
      >
        <!-- ALERT CURSOS -->
        <b-alert
          v-if="selectedCurso === null"
          :show="selectedCurso === null"
          variant="primary"
          style="margin-top: -5px"
        >
          <div class="alert-body text-center mt-25">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            Seleccione un curso.
          </div>
        </b-alert>
        <!-- ALERT PERIODO -->
        <b-alert
          v-else-if="selectedPeriodo === null"
          show
          variant="primary"
          style="margin-top: -5px"
        >
          <div class="alert-body text-center mt-25">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            Seleccione un periodo.
          </div>
        </b-alert>

        <!-- ALERT INFORME -->
        <b-alert
          v-else-if="selectedTipoInforme === null"
          show
          variant="primary"
          style="margin-top: -5px"
        >
          <div class="alert-body text-center mt-25">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            Seleccione un informe.
          </div>
        </b-alert>
      </div>
    </BCard>
  </BOverlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BFormCheckbox, BOverlay, BCard, BFormSelect,
  BAlert, BButton, BCardText, BMedia, BMediaAside, BAvatar, BMediaBody
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'

import vSelect from 'vue-select'

// COMPONENTES RECICLADOS
import btnCrear from '../components/List/btnCrear.vue'
import inputFiltro from '../components/List/inputFiltro.vue'
import btnMostrar from '../components/List/btnMostrar.vue'
import spinner from '../components/spinner.vue'
import colNombreImg from '../components/List/colNombreImg.vue'
import estudianteRetirado from '../components/List/estudianteRetirado.vue'
import accionesPersonalidad from './components/accionesPersonalidad.vue'
import accionesParcial from './components/accionesParcial.vue'
import accionesPeriodo from './components/accionesPeriodo.vue'
import accionesAnual from './components/accionesAnual.vue'

export default {
  components: {
    BTable, BRow, BCol, BPagination, BFormCheckbox, BOverlay, BCard, BFormSelect,
    BAlert, BButton, BCardText, BMedia, BMediaAside, BAvatar, BMediaBody,

    vSelect,

    // COMPONENTES RECICLADOS
    estudianteRetirado,
    btnCrear,
    inputFiltro,
    btnMostrar,
    spinner,
    colNombreImg,
    accionesPersonalidad,
    accionesParcial,
    accionesPeriodo,
    accionesAnual,
  },
  data() {
    return {
      spinner: false,
      cargando: true,
      cargandoCursos: true,
      cargandoTipoInformes: false,
      cargandoPeriodos: true,
      cargandoInforme: false,

      bloquesAlert: false,
      alertMsg: 'El curso no tiene Estudiantes Matriculados.',

      // SELECTS
      selectedCurso: null,
      selectedPeriodo: null,
      selectedTipoInforme: null,
      optionsCursos: [],
      optionsPeriodos: [],
      optionsTipoInformes: [],
      cuentaAlumnos: [],

      // ARRAYS
      items: [],
      // informe personalidad
      informePersonalidadCurso: [],

      // CHK
      selectedchk: [],
      chkTodo: null,
      checked: null,

      // TABLE
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      fields: [
        // CHK
        {
          key: 'colCheck',
          label: 'chkHeader',
          sortable: false,
          visible: false,
          thStyle: {
            width: '0% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // ORDEN
        {
          key: 'orden',
          label: '#',
          sortable: false,
          visible: true,
          thStyle: {
            width: '0% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // NOMBRE COMPLETO
        {
          key: 'nombre_completo',
          label: 'Estudiantes',
          sortable: false,
          visible: true,
          stickyColumn: true,
          thStyle: {
            width: '40% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },

        // INFORME NOTAS PARCIAL
        {
          id_informe: '1',
          key: 'observacion_parcial',
          label: 'Observaciones',
          sortable: false,
          visible: false,
          thStyle: {
            width: '40% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          id_informe: '1',
          key: 'acciones_parcial',
          label: 'Acciones',
          sortable: false,
          visible: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // INFORME NOTAS PERIODO
        {
          id_informe: '2',
          key: 'observacion_periodo',
          label: 'Observación',
          sortable: false,
          visible: false,
          thStyle: {
            width: '40% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          id_informe: '2',
          key: 'acciones_periodo',
          label: 'Acciones',
          sortable: false,
          visible: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // INFORME ANUAL
        {
          id_informe: '6',
          key: 'observacion_anual',
          label: 'Observación',
          sortable: false,
          visible: false,
          thStyle: {
            width: '40% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          id_informe: '6',
          key: 'acciones_anual',
          label: 'Acciones',
          sortable: false,
          visible: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // INFORME DE PERSONALIDAD
        {
          id_informe: '5',
          key: 'acciones_personalidad',
          label: 'Acciones',
          sortable: false,
          visible: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
      getAlumnos: 'personas/getAlumnos',
      getPeriodosEstablecimiento: 'periodos/getPeriodosEstablecimiento',
      getObservaciones: 'informes/getObservaciones',
      getInformesPersonalidad: 'informesPersonalidad/getInformesPersonalidad',
    }),
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
    disabledPeriodos() {
      return this.selectedCurso === null
    },
    disabledCursos() {
      return false
    },
    disabledTipoInforme() {
      return this.selectedPeriodo === null
    },
  },
  watch: {
    getObservaciones(getObservaciones) {
      this.setObservacionesFunction(getObservaciones)
      this.cargando = false
    },
    getAlumnos(alumnos) {
      if (alumnos.length !== 0) {
        this.setAlumnos(alumnos)
        this.contarAlumnos(alumnos)
      } else {
        this.bloquesAlert = true
      }
      this.cargando = false
    },
    getInformesPersonalidad(informe_personalidad) {
      this.setIndicadoresInformePersonalidad(informe_personalidad)
    },
    chkTodo() {
      this.chkAll()
    },
  },
  mounted() {
    this.cargaCursosPersonaRol()
    this.cargaPeriodos(this.getUser.id_establecimiento)
    this.cargaTipoInformes(this.selectedCurso, this.selectedPeriodo)
  },
  methods: {
    ...mapActions({
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
      fetchAlumnos: 'personas/fetchAlumnos',
      fetchPeriodosEstablecimiento: 'periodos/fetchPeriodosEstablecimiento',
      fetchPersonalidadesGrado: 'personalidades/fetchPersonalidadesGrado',
      fetchInformesPersonalidad: 'informesPersonalidad/fetchInformesPersonalidad',
      fetchObservaciones: 'informes/fetchObservaciones',
    }),
    // * CARGAS
    // Filtro
    cargaCursosPersonaRol() {
      this.cargandoCursos = true
      this.fetchCursosPersonaRol().then(() => {
        this.setCursosOptions(this.getCursosPersonaRol)
        this.cargandoCursos = false
        this.cargando = false
      })
    },
    cargaPeriodos(id_establecimiento) {
      this.cargandoPeriodos = true
      this.fetchPeriodosEstablecimiento(id_establecimiento).then(() => {
        this.setPeriodosOptions(this.getPeriodosEstablecimiento)
        this.cargandoPeriodos = false
      })
    },
    cargaTipoInformes(selectedCurso, selectedPeriodo) {
      this.cargandoTipoInformes = true
      this.setTipoInformes(selectedCurso, selectedPeriodo)
    },
    // Data Table
    async cargaAlumnos(id_curso) {
      this.cargando = true
      await this.fetchAlumnos(id_curso)
    },
    contarAlumnos(alumnos) {
      const alumnosMatriculados = alumnos.filter(
        (alumno) => alumno.fecha_retiro === null
      ).length;
      const alumnosRetirados = alumnos.filter(
        (alumno) => alumno.fecha_retiro !== null
      ).length;

      // Suma total
      const alumnosTotal = alumnosMatriculados + alumnosRetirados;
      
      this.cuentaAlumnos = [
        {
          icon: 'UserCheckIcon',
          color: 'light-info',
          title: alumnosMatriculados,
          subtitle: 'Matriculados',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserMinusIcon',
          color: 'light-secondary',
          title: alumnosRetirados,
          subtitle: 'Retirados',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'UsersIcon',
          color: 'light-primary',
          title: alumnosTotal,
          subtitle: 'Total',
          customClass: 'mb-2 mb-xl-0',
        },
      ];
    },

    cargaObservacionesFunction(tipo_informe) {
      if (tipo_informe === 1) {
        this.cargaObservacionesInformeParcial(this.selectedCurso)
      }
      if (tipo_informe === 2) {
        this.cargaObservacionesInformePeriodo(this.selectedCurso)
      }
      if (tipo_informe === 5) {
        this.cargaObservacionesInformePersonalidad(this.selectedCurso)
      }
      if (tipo_informe === 6) {
        this.cargaObservacionesInformeAnual(this.selectedCurso)
      }
    },
    setObservacionesFunction(observaciones) {
      if (this.selectedTipoInforme === 1) {
        this.setObservacionesInformeParcial(observaciones)
      }
      if (this.selectedTipoInforme === 2) {
        this.setObservacionesInformePeriodo(observaciones)
      }
      if (this.selectedTipoInforme === 6) {
        this.setObservacionesInformeAnual(observaciones)
      }
    },
    cargaObservacionesInformeParcial(id_curso) {
      this.cargando = true
      const data = {
        id_curso,
        tipo: 1, //TIPO: Informe parcial
        id_periodo: this.selectedPeriodo
      }
      this.fetchObservaciones(data)
    },
    cargaObservacionesInformePeriodo(id_curso) {
      this.cargando = true
      const data = {
        id_curso,
        tipo: 2, //TIPO: Informe periodo
        id_periodo: this.selectedPeriodo
      }
      this.fetchObservaciones(data)
    },
    cargaObservacionesInformeAnual(id_curso) {
      this.cargando = true
      const data = {
        id_curso,
        tipo: 6, //TIPO: Informe Anual
        id_periodo: null
      }
      this.fetchObservaciones(data)
    },
    cargaConceptos(id_curso) {
      this.fetchPersonalidadesGrado(id_curso)
    },
    cargaObservacionesInformePersonalidad(id_curso) {
      this.cargando = true
      this.cargaConceptos(id_curso)
      const data = {
        id_curso,
        id_periodo: this.selectedPeriodo
      }
      this.fetchInformesPersonalidad(data).then(() => {
        this.setIndicadoresInformePersonalidad(this.getInformesPersonalidad)
        this.cargando = false
      })
    },

    // * SETEOS
    // Filtro
    setCursosOptions(cursos) {
      this.optionsCursos = []
      cursos.forEach(curso => {
        let text = this.getUser.id_persona_rol === curso.id_persona_rol_docente_jefe
          ? curso.nombre+' '+curso.letra+' ★ '
          : curso.nombre+' '+curso.letra
        this.optionsCursos.push({
          value: curso.id,
          text,
          id_persona_rol_docente_jefe: curso.id_persona_rol_docente_jefe,
          id_tipo_ensenanza: curso.id_tipo_ensenanza
        })
      })
      this.cargandoCursos = false
    },
    setPeriodosOptions(periodos) {
      this.optionsPeriodos = []
      periodos.forEach(periodo => {
        this.optionsPeriodos.push({
          value: periodo.id,
          text: periodo.nombre,
        })
      })

      this.optionsPeriodos.push({
        value: 0,
        text: 'Anual',
      })

      this.selectedPeriodo = null
    },
    setTipoInformes(selectedCurso, selectedPeriodo) {
      if (selectedCurso !== null && selectedPeriodo !== null) {
        this.optionsTipoInformes = []
        const curso = this.optionsCursos.find(c => c.value === selectedCurso)
        if (this.selectedPeriodo === 0) { // Anual
          this.optionsTipoInformes.push(
            {
              value: 6,
              text: 'Informe anual',
              chkAlumnos: true,
            },
            {
              value: 4,
              text: 'Informe de ranking',
              chkAlumnos: false,
            },
            {
              value: 3,
              text: 'Planilla del curso notas',
              chkAlumnos: false,
            },
            {
              value: 7,
              text: 'Planilla del curso promedios',
              chkAlumnos: false,
            },
          )
          if (this.optionsTipoInformes.length === 1) {
            this.selectedTipoInforme = 6
            this.changeTipoInforme()
          }
        } else {
          this.optionsTipoInformes.push(
            {
              value: 1,
              text: 'Informe parcial',
              chkAlumnos: true,
            },
            {
              value: 2,
              text: 'Informe periodo',
              chkAlumnos: true,
            },
            {
              value: 3,
              text: 'Planilla del curso notas',
              chkAlumnos: false,
            },
            {
              value: 4,
              text: 'Informe de ranking',
              chkAlumnos: false,
            },
          )

          // INFORMES PROFESOR JEFE
          if (this.getUser.id_persona_rol === curso.id_persona_rol_docente_jefe) {
            this.optionsTipoInformes.push(
              {
                value: 5,
                text: 'Informe de Personalidad',
                chkAlumnos: true,
              }
            )
          } else if (this.getUser.id_rol === 3 || this.getUser.id_rol === 4) {
            this.optionsTipoInformes.push(
              {
                value: 5,
                text: 'Informe de Personalidad',
                chkAlumnos: true,
              }
            )
          }
        }
      }
      this.cargandoTipoInformes = false
      this.cargando = false
    },
    // Data Table
    setAlumnos(estudiantes) {
      let curso = this.getCursosPersonaRol.find(c => c.id === this.selectedCurso)
      curso = `${curso.nombre} ${curso.letra}`
      for (let i = 0; i < estudiantes.length; i++) {
        let estudiante = estudiantes[i];
        estudiante['nombre_completo'] = `${estudiante.primer_apellido}
                  ${estudiante.segundo_apellido}
                  ${estudiante.nombre}
                  ${estudiante.segundo_nombre !== null
                    ? estudiante.segundo_nombre
                    : ''}
                  `
        estudiante['fecha_retiro'] = estudiante.fecha_retiro
        estudiante['curso'] = curso
      }
      this.items = []
      this.items = estudiantes
    },
    setObservacionesInformeParcial(observaciones) {
      this.items.forEach(item => {
        const observacion = observaciones.find(o => o.id_persona_rol_alumno === item.id_persona_rol_alumno)
        item.observacionParcial = observacion
      })
      this.setTable()
      const curso = this.optionsCursos.find(c => c.value === this.selectedCurso)
      const fields = this.fields.filter(f => f.id_informe === '1')
      fields.forEach(field => {
        if (field.label === 'Acciones') {
          if (this.getUser.id_persona_rol === curso.id_persona_rol_docente_jefe || this.getUser.id_rol === 4) {
            field.visible = true
          }
        } else {
          field.visible = true
        }
      })
    },
    setObservacionesInformePeriodo(observaciones) {
      this.items.forEach(item => {
        const observacion = observaciones.find(o => o.id_persona_rol_alumno === item.id_persona_rol_alumno)
        item.observacionPeriodo = observacion
      })
      this.setTable()
      const curso = this.optionsCursos.find(c => c.value === this.selectedCurso)
      const fields = this.fields.filter(f => f.id_informe === '2')
      fields.forEach(field => {
        if (field.label === 'Acciones') {
          if (this.getUser.id_persona_rol === curso.id_persona_rol_docente_jefe || this.getUser.id_rol === 4) {
            field.visible = true
          }
        } else {
          field.visible = true
        }
      })
    },
    setObservacionesInformeAnual(observaciones) {
      this.items.forEach(item => {
        const observacion = observaciones.find(o => o.id_persona_rol_alumno === item.id_persona_rol_alumno)
        item.observacionAnual = observacion
      })
      this.setTable()
      const curso = this.optionsCursos.find(c => c.value === this.selectedCurso)
      const fields = this.fields.filter(f => f.id_informe === '6')
      fields.forEach(field => {
        if (field.label === 'Acciones') {
          if (this.getUser.id_persona_rol === curso.id_persona_rol_docente_jefe || this.getUser.id_rol === 4) {
            field.visible = true
          }
        } else {
          field.visible = true
        }
      })
    },
    setIndicadoresInformePersonalidad(informes_personalidad) {
      this.items.forEach(item => {
        const informe_personalidad = informes_personalidad.find(i => i.id_persona_rol_alumno === item.id_persona_rol_alumno)
        item.personalidad = informe_personalidad.personalidad
        if (item.personalidad.length === 0) {
          item.personalidad = {}
        }
      })
      this.setTable()
      const fields = this.fields.filter(f => f.id_informe === '5')
      fields.forEach(field => {
        field.visible = true
      })
    },

    // * INPUT ACTIONS
    changeCurso() {
      this.setTable()
      this.selectedTipoInforme = null
      this.selectedPeriodo = null
      this.chkTodo = null
      this.fields[0].visible = false
      if (this.selectedCurso !== null) {
        this.cargaAlumnos(this.selectedCurso)
      }
    },
    changePeriodo() {
      this.selectedTipoInforme = null
      this.setTable()
      this.chkTodo = null
      this.fields[0].visible = false
      if (this.selectedPeriodo !== null) {
        this.cargaTipoInformes(this.selectedCurso, this.selectedPeriodo)
      }
    },
    changeTipoInforme() {
      this.setTable()
      const informe = this.optionsTipoInformes.find(ti => ti.value === this.selectedTipoInforme)
      this.chkTodo = null
      this.fields[0].visible = informe.chkAlumnos
      this.cargaObservacionesFunction(informe.value)
    },

    setTable() {
      const fieldsParcial = this.fields.filter(f => f.id_informe === '1')
      fieldsParcial.forEach(fieldParcial => {
        fieldParcial.visible = false
      })

      const fieldsPeriodo = this.fields.filter(f => f.id_informe === '2')
      fieldsPeriodo.forEach(fieldPeriodo => {
        fieldPeriodo.visible = false
      })

      const fieldsPersonalidad = this.fields.filter(f => f.id_informe === '5')
      fieldsPersonalidad.forEach(fieldPersonalidad => {
        fieldPersonalidad.visible = false
      })

      const fieldsAnual = this.fields.filter(f => f.id_informe === '6')
      fieldsAnual.forEach(fieldAnual => {
        fieldAnual.visible = false
      })
    },

    verInforme() {
      this.cargandoInforme = true
      const itemsChecked = this.items.filter(i => i.chkSelected === true)
      const { chkAlumnos } = this.optionsTipoInformes.find(ti => ti.value === this.selectedTipoInforme)
      if (chkAlumnos === false) {
        const data = {
          id_curso: this.selectedCurso,
          id_periodo: this.selectedPeriodo,
        }
        if (this.selectedTipoInforme === 4) {
          this.informeRanking(data)
        }

        if (this.selectedTipoInforme === 3) {
          this.planillaCursoNotas(data)
        }

        if (this.selectedTipoInforme === 7) {
          this.planillaCursoPromedios(data)
        }
      } else {
        let alumnos = []
        itemsChecked.forEach(alumno => {
          alumnos.push({ id_alumno: alumno.id_persona_rol_alumno})
        });

        if (itemsChecked.length !== 0) {
          const data = {
            alumnos,
            idcurso: this.selectedCurso,
            idperiodo: this.selectedPeriodo,
            tipo: this.selectedTipoInforme,
            endpoint: null
          }

          // INFORME PARCIAL
          if (this.selectedTipoInforme === 1) {
            data.endpoint = 'informe'
            this.imprimeInformesPost(data)
          }

          // INFORME PERIODO
          if (this.selectedTipoInforme === 2) {
            data.endpoint = 'informe'
            this.imprimeInformesPost(data)
          }

          // INFORME ANUAL
          if (this.selectedTipoInforme === 6) {
            data.endpoint = 'informe'
            this.imprimeInformesPost(data)
          }

          // INFORME PERSONALIDAD
          if (this.selectedTipoInforme === 5) {
            data.endpoint = 'informepersonalidad'
            this.imprimeInformesPost(data)
            // this.informePersonalidad(data)
          }
        } else {
            this.mensajeAlumnoNoCheck()
        }
      }
    },
    informeRanking(data) {
      const tipo = data.id_periodo === 0 ? '2' : '1';
      const tipoInforme = `informeranking/${data.id_curso}/${data.id_periodo}/${tipo}`
      this.imprimeInformes(tipoInforme)
    },
    planillaCursoNotas(data) {
      const tipo = data.id_periodo === 0 ? '2' : '1';
      const tipoInforme = `informecurso/${data.id_curso}/${data.id_periodo}/${tipo}`
      this.imprimeInformes(tipoInforme)
    },
    planillaCursoPromedios (data) {
      const tipo = data.id_periodo === 0 ? '4' : '3';
      const tipoInforme = `informecurso/${data.id_curso}/${data.id_periodo}/${tipo}`
      this.imprimeInformes(tipoInforme)
    },

    imprimeInformes(url) {
      axios({
        url,
        method: 'GET',
        responseType: 'blob',
        data: {
          // Agrega aquí los datos que necesitas enviar
          clave1: 'valor1',
          clave2: 'valor2'
        },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data],
                        {type: 'application/pdf'}
                      ))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('target', '_blank')
        document.body.appendChild(fileLink)
        fileLink.click()
        this.cargandoInforme = false
      }).catch((error) => {
        this.cargandoInforme = false;
      });
    },

    imprimeInformesPost(datos) {
      axios({
        url: `${axios.defaults.baseURL}/${datos.endpoint}`,
        method: 'POST',
        responseType: 'blob',
        data: {
          alumnos: datos.alumnos,
          idcurso: datos.idcurso,
          idperiodo: datos.idperiodo,
          tipo: datos.tipo
        },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data],
                        {type: 'application/pdf'}
                      ))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('target', '_blank')
        document.body.appendChild(fileLink)
        fileLink.click()
        this.cargandoInforme = false
      }).catch((error) => {
        this.cargandoInforme = false;
      });
    },

    mensajeAlumnoNoCheck() {
      this.cargandoInforme = false
      this.$swal({
        title: 'Información',
        html: 'Para acceder a los informes, seleccione uno o varios estudiantes marcando la casilla a la izquierda de la tabla.',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Ok!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
        } else {
          return false
        }
      })
    },

    cortaObservacion(observacion) {
      let txtObservacion = observacion
      if (observacion.length > 70) {
        txtObservacion = observacion.slice(0, 70)+'...'
      }
      return txtObservacion
    },

    // Checkbox select item Table
    chkAll() {
      this.items.forEach(item => {
        const cliente = this.items.find(i => i.id === item.id)
        cliente.chkSelected = this.chkTodo
      })
    },
    chkCount() {
      let chkCount = 0
      this.items.forEach(item => {
        chkCount = item.chkSelected
          ? chkCount + 1
          : chkCount
      })
      return chkCount === 0
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .text-center {
    text-align: center;
  }
</style>
